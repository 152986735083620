/* === SECTION PRINCIPALE === */
.scroll-section {
  overflow-x: hidden;
}

/* === GRILLE DES SONS === */
.sound-examples {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

/* === BLOCS AUDIO === */
.sound-example {
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-bottom: 20px;
}

.sound-example.playing {
  /* Effets supplémentaires si besoin */
}

/* === CONTAINER POUR IMAGE, TITRE & ICÔNE === */
.thumbnail-container {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  overflow: hidden;
}

/* === IMAGE === */
.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: filter 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

/* === ACCORDÉON (Titre et description regroupés) === */
.accordion {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.accordion.expanded {
  max-height: 200px; /* Ajustez selon le contenu */
  opacity: 1;
}

.accordion.collapsed {
  max-height: 0;
  opacity: 0;
}

/* Styles pour le titre dans l'accordéon */
.title {
  font-size: 16px;
  color: rgb(0, 0, 0);
  padding: 5px 10px;
  border-radius: 10px;
  margin: 8px 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

/* Styles pour la description dans l'accordéon */
.description-text {
  font-size: 12px;
  line-height: 1.5;
  color: #000;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 0;
}

/* Style pour le tag popup */
.popup-trigger {
  color: #ff7300;
  text-decoration: underline;
  cursor: pointer;
  font-size: 10px; /* Ajustez à votre besoin */
}

/* === ICÔNE PLAY/PAUSE === */
.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sound-example.playing .icon,
.sound-example:hover .icon {
  opacity: 1;
}

/* === ANIMATION OMBRE PULSANTE === */
@keyframes shadowPulse {
  0% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transform: translateY(0);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
    transform: translateY(-3px);
  }
  100% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transform: translateY(0);
  }
}

.thumbnail.playing {
  filter: brightness(0.5);
  animation: shadowPulse 3s infinite alternate;
  transform: scale(1.1);
}

.thumbnail-container:hover .thumbnail {
  transform: scale(1.05);
  filter: brightness(0.8);
}

/* === MINI PLAYER === */
.mini-player {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  background: rgba(34, 34, 34, 0.8);
  color: white;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 2px solid #444;
  z-index: 1000;
  backdrop-filter: blur(10px);
  opacity: 0.95;
}

.player-controls button {
  background-color: transparent;
  border: none;
  padding: 12px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.player-controls button svg {
  color: white;
  font-size: 1rem;
}

.player-controls button:hover {
  background-color: rgba(34, 34, 34, 1);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.4);
}

.player-controls button:focus {
  outline: none;
}

/* === PROGRESSION === */
.progress-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 0 10px;
  cursor: pointer;
}

.progress-bar {
  flex-grow: 1;
  height: 8px;
  background: #555;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 10px;
  cursor: pointer;
}

.progress {
  height: 100%;
  background: #ff9800;
  transition: width 0.1s;
}

.time {
  font-size: 12px;
  width: 30px;
  text-align: center;
}

/* === CONTRÔLE VOLUME === */
.volume-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.volume-icon {
  cursor: pointer;
}

.volume-slider {
  position: absolute;
  bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.6s ease-in-out, max-height 0.6s ease-in-out;
  border-radius: 10px;
  padding-top: 20px;
}

.volume-slider.visible {
  opacity: 0.9;
  max-height: 120px;
}

/* Slider vertical */
.vertical-slider {
  -webkit-appearance: slider-vertical;
  width: 12px;
  height: 150px;
  accent-color: #ff9800;
  border-radius: 10px;
  background: transparent;
}

/* === POPUP MODAL === */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 50% !important;
  position: relative;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  text-align: justify;
  font-size: 14px; /* Taille de base du texte */
  line-height: 1.6; /* Espacement pour améliorer la lisibilité */
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

/* === RESPONSIVE === */
@media (max-width: 1200px) {
  .sound-examples {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .sound-examples {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .thumbnail-container {
    width: 180px;
    height: 180px;
  }
  
  .title {
    font-size: 14px;
    padding: 4px;
  }
  
  .popup-content {
    width: 80%;
    max-width: 400px;
  }
}

@media (max-width: 480px) {
  .sound-examples {
    grid-template-columns: repeat(3, 30%);
    justify-content: center;
  }
  
  .thumbnail-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
  }
  
  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .title {
    font-size: 12px;
    padding: 2px;
  }
  
  .mini-player {
    width: 90%;
  }
  
  .popup-trigger {
    font-size: 8px;
  }
  
}

/* Couleur des icônes dans le mini-player */
.player-controls button svg {
  color: white;
}
