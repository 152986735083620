@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

/* Style global */
.home-container {
    display: flex;
    height: 100vh; /* Utilisation de la hauteur de l'écran */
    width: 100%;   /* La largeur prend toute la largeur de l'écran */
}



.left {
    background-color: #f0f0f0;
    border-right: 2px solid #ddd; /* Séparation nette */
}

.right {
    background-color: #ddd;
}


/* Une moitié gauche et droite */
.half-screen {
    flex: 1; /* Chaque côté prend 50% de la largeur de l'écran */
    height: 100%; /* Les côtés prennent toute la hauteur de l'écran */
    position: relative;
    overflow: hidden; /* Cache tout débordement des images */
    display: flex; /* Utilisation de flexbox pour centrer le texte */
    justify-content: center; /* Centre le texte horizontalement */
    align-items: center; /* Centre le texte verticalement */
    transition: transform 0.3s ease; /* Transition douce pour l'effet de zoom */
}

/* Côté gauche avec un fond gris clair */
.left {
    background-color: #f0f0f0;
}

/* Côté droit avec un fond gris */
.right {
    background-color: #ddd;
}

/* Les images sont centrées et prennent toute la largeur de leur conteneur */
.half-screen img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centrage de l'image */
    width: 100%;   /* L'image prend toute la largeur de son conteneur */
    height: 100%;  /* L'image prend toute la hauteur de son conteneur */
    object-fit: cover; /* Les images couvrent l'espace sans se déformer */
    object-position: center; /* L'image est centrée, donc le rognage se fait autour du centre */
    z-index: 0; /* L'image doit être en arrière-plan */
    transition: filter 0.3s ease, transform 0.3s ease; /* Transition pour l'effet de zoom et de luminosité */
}

/* Effet d'assombrissement et agrandissement de l'image au survol */
.half-screen:hover img {
    filter: brightness(50%); /* Réduit la luminosité de l'image de 50% */
    transform: translate(-50%, -50%) scale(1.05); /* Agrandit légèrement l'image au survol */
}

/* Overlay pour rendre les textes visibles */
.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-weight: bold;
    z-index: 1;
    opacity: 0.8;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.overlay h1 {
    color: white;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
    font-family: 'Playfair Display', serif;
    letter-spacing: 2px;
    line-height: 1.4;
    opacity: 0.5;
    transition: transform 0.3s ease, opacity 0.3s ease;
    font-size: 15vw; /* Augmenter la taille du texte en pourcentage de la largeur de l'écran */

}

/* Création du cercle qui tourne autour du texte */
.overlay::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40vw;  /* Le cercle prend 40% de la largeur de l'écran sur un smartphone */
    height: 39.2vw; /* Le cercle prend 40% de la hauteur de l'écran sur un smartphone */
    border: 3px solid white; /* Bordure blanche */
    border-radius: 50%; /* Cercle parfait */
    transform: translate(-50%, -50%); /* Centrer le cercle par rapport au texte */
    animation: pulse 4s infinite, spin 5s infinite linear; /* Ajout de l'animation pulse et spin */
    opacity: 0.5; /* Légèrement transparent pour voir l'effet de texte en dessous */
}

/* Animation de rotation */
@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg); /* Le cercle effectue une rotation complète */
    }
}

/* Effet de pulsation (comme une vague) */
@keyframes pulse {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.3;
    }
    50% {
        transform: translate(-50%, -50%) scale(1.2); /* Agrandissement du cercle */
        opacity: 0.9;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.3;
    }
}

/* Agrandissement du texte vers l'avant et opacité du texte au survol */
.half-screen:hover .overlay {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
}

.half-screen:hover .overlay::before {
    animation: pulse 5s infinite, spin 2s infinite linear; /* Accélération des animations au survol */
}

/* Taille du texte */
.overlay h1 {
    font-size: 5vw; /* La taille du texte est aussi responsive (4% de la largeur de l'écran) */
}

/* Pour les écrans plus grands */
@media (min-width: 768px) {
    .home-container {
        height: 100vh; /* Fixer la hauteur du conteneur à la hauteur de l'écran */
        overflow: hidden; /* Empêcher le défilement */
    }

    .overlay::before {
        width: 20vw;  /* Réduire la taille du cercle à 20% de la largeur */
        height: 20vw; /* Réduire la taille du cercle à 20% de la hauteur */
    }

    .overlay h1 {
        font-size: 2rem; /* Taille du texte plus grande sur les écrans moyens */
    }
}

/* Pour les très grands écrans */
@media (min-width: 1200px) {
    .overlay::before {
        width: 18vw;  /* Cercle encore plus petit sur les très grands écrans */
        height: 17.9vw;
    }

    .overlay h1 {
        font-size: 3rem; /* Taille du texte plus grande sur les très grands écrans */
    }
}
@media (max-width: 768px) {
    /* Contrôle spécifique de l'image de gauche */
    .left img {
        transform: translate(-50%, -50%) scale(1);
        object-position: 56% center; /* L'image se déplace vers la droite */

    }
    }

    /* Contrôle spécifique de l'image de droite */
    .right img {
        width: 100%; /* L'image de droite prend toute la largeur */
        height: 100%; /* Hauteur fixée à 90% du conteneur */
    }