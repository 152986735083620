.section-title {
  border-bottom: 0.5px solid #4e4e4e; /* Ajoute une bordure inférieure de 0.5px solide */
  padding-bottom: 10px;

  padding: 40px 0; /* Espace entre le texte et la bordure */
  margin: 0 auto 60px; /* Centre horizontalement avec marge inférieure de 20px */
  width: 30%; /* Définit la largeur à 50% de son conteneur */

}

@media (max-width: 600px) {
 .section-title {
  border-bottom: 0.5px solid #4e4e4e; /* Ajoute une bordure inférieure de 0.5px solide */
  padding: 20px 0; /* Espace entre le texte et la bordure */
  margin: 10px auto 10px; /* Ajoute une marge supérieure de 10px, centre horizontalement avec marge inférieure de 40px */
  width: 40%; /* Définit la largeur à 50% de son conteneur */
}
}