.footer {
  width: 100%;
  background-color: white;
  color: rgb(31, 31, 31);
  text-align: center;
  padding: 0px 0;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative; /* Position statique */
  margin-top: 50px; /* Ajoutez une marge en haut */
  margin-bottom: 20px; /* Ajoutez une marge en bas */
}

.footer a {
  color: black;
  text-decoration: none;
  font-size: 13px; /* Ajustez la taille du texte ici */
  transition: transform 0.3s ease, color 0.3s ease; /* Ajoutez une transition pour l'effet de zoom et la couleur */
  position: relative; /* Nécessaire pour positionner le pseudo-élément */
}

.footer a:hover {
  text-decoration: none;
  transform: scale(1.1); /* Effet de zoom */
  color: #ff6347; /* Changez la couleur du texte au survol */
}


@media (max-width: 768px) {
  .footer {
    font-size: 12px; /* Taille du texte plus petite */
    margin-top: 20px; /* Ajoutez une marge en haut */

  }

  .footer a {
    font-size: 10px; /* Taille du texte plus petite */
  }
}