/* In MediaSection.css */
.custom-link {
  display: inline-block;
  color: black;
  text-decoration: none;
  transition: transform 0.2s; /* Smooth transition */
}

.custom-link:hover {
  transform: scale(1.07); /* Zoom in on hover */
}
.media-title {
  margin-bottom: 70px; /* Espace sous le titre */
}


.media-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto 0; /* Centre la galerie et empêche le débordement */
  max-width: 100%; /* Assure que la galerie ne dépasse pas la largeur de la fenêtre */
  box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
}

.media-item {
  flex: 1 1 calc(25% - 20px); /* Les éléments prennent 25% de la largeur moins l'espace du gap */
  max-width: calc(25% - 20px); /* Limite la taille maximale des éléments */
  position: relative;
  overflow: hidden;
  cursor: pointer; /* Change le curseur en une main pour les éléments cliquables */
}

.media-item img {
  width: 60%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 10px; /* Ajoute des bords arrondis aux images */
}

.media-item:hover img {
  transform: scale(1.1);
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(202, 202, 202, 0.5); /* Changez la couleur de fond à transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 1s ease, transform 1s ease;
}

.popup-content {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content img {
  width: auto;
  height: auto;
  max-width: 75%;
  max-height: 75%;
  border-radius: 10px;
  
}

/* Media queries pour ajuster les vignettes sur les petits écrans */
@media (max-width: 1200px) {
  .media-item {
    flex: 1 1 calc(50% - 20px); /* Les éléments prennent 33.33% de la largeur moins l'espace du gap */
    max-width: calc(100% - 100px); /* Limite la taille maximale des éléments */
  }
}

@media (max-width: 768px) {

  .media-gallery {
    gap: 10px 10px; /* Espace horizontal (10px) et vertical (5px) séparés */
  }

  .media-item {
    max-width: 45%; /* Limite la taille maximale des éléments */
  }

  .media-item img {
    width: 100%; /* Prend toute la largeur de la vignette */
    height: auto;
    object-fit: cover;
  }
  
    .popup-content img {
      max-width: 100%;
      height: auto; /* Maintient le ratio de l'image */
      transform: scale(1.8); /* Agrandit l'image dans la pop-up */
    }
  
  .popup-content {
    width: 90%; /* Utilisation de la largeur de la fenêtre */
    max-width: 100%;
    padding: 15px; /* Ajuste le padding */
  }
}