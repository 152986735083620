.header-title {
  font-size: 3rem; /* Taille par défaut */
}

.background-photo {
  background-size: cover;
  background-position: center;
  height: 100vh; /* Hauteur pleine écran */
  position: relative;
  z-index: 1;
  width: 100%;
  overflow: hidden;
}

.white-band {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 3;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
}

.text-left {
  text-align: center;
  z-index: 4;
}

/* Barre de navigation (pour version desktop) */
.sections {
  display: flex;
  justify-content: center;
  gap: clamp(70px, 1%, 17%);
  z-index: 4;
  text-align: center;
  transition: gap 0.3s ease;
}
.sections.open {
  display: block;
}

/* Icônes sociales */
.social-icons {
  display: flex;
  justify-content: flex-end;
  z-index: 4;
}
.social-icons img {
  width: 30px;
  margin: 0 20px;
  transition: transform 0.3s ease;
  transform-origin: center;
}
.social-icons img:hover {
  transform: scale(1.2);
}

/* Flèche invitant à descendre (uniquement pour smartphone) */
.scroll-arrow {
  display: none; /* Par défaut, cachée */
}

.fuzzy-overlay {
  position: absolute;
  inset: -1000%;
  background-image: url("../image/noise.png");
  opacity: 0.05;
  z-index: 0;
  animation: shift 0.4s linear infinite both;
}

@keyframes shift {
  0% {
    transform: translateX(10%) translateY(10%);
  }
  100% {
    transform: translateX(-10%) translateY(-10%);
  }
}

  .separator {
    width: 80%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.7);
    margin: 10px auto;
  }

/* Styles pour smartphone */
@media (max-width: 768px) {
  .background-photo {
    height: 100vh;
    max-height: 100vh;
    background-size: cover;
    background-position: center;
  }
  .white-band {
    flex-direction: column;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    position: static;
  }
  .text-left {
    text-align: center;
  }
  .text-left h1 {
    font-size: 2rem;
  }
  .text-left h2 {
    font-size: 1.2rem;
  }
  .social-icons {
    justify-content: center;
    margin-bottom: 20px;
  }
  .social-icons img {
    width: 20px;
    margin: 0 15px;
  }
  /* Affichage de la flèche en smartphone */
  .scroll-arrow {
    display: block;
    position: absolute;
    top: 75%; /* Plus haute dans l'image */
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4rem; /* Grande et voyante */
    color: rgba(214, 214, 214, 0.76); /* Presque opaque */
    cursor: pointer;
    z-index: 5;
    animation: bounce 2s infinite;
    user-select: none;
    opacity: 80%;
  }
}

/* Pour desktop, la flèche est cachée */
@media (min-width: 769px) {
  .scroll-arrow {
    display: none;
  }
}

/* Animation de rebond */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate(-50%, 0);
  }
  40% {
    transform: translate(-50%, -10px);
  }
  60% {
    transform: translate(-50%, -5px);
  }
}

/* Ajustements pour d'autres tailles d'écran */
@media (max-width: 1570px) and (min-width: 1200px) {
  .sections {
    gap: 18%;
  }
  .sections h3 {
    font-size: 1.5rem;
  }
  .header-title {
    font-size: 2.5rem;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .sections {
    gap: 15%;
  }
  .sections h3 {
    font-size: 1rem;
  }
  .header-title {
    font-size: 2rem;
  }
}
