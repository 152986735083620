/* Styles pour le lien avec effet de zoom */
.zoom-link {
  color: #000000c9;
  transition: transform 0.3s ease;
  text-decoration: underline;
  text-decoration-thickness: 0.6px;
  text-underline-offset: 3px;
  display: inline-block;
  cursor: pointer;
}
.toggle-icon {
  font-size: 2rem;;
  margin-right: 5px;
  transition: transform 0.4s ease, opacity 0.4s ease;
  cursor: pointer;
}
.zoom-link:hover {
  transform: scale(1.04);
}
.section-title {
  display: flex;
  align-items: center;
  justify-content: center; /* Centre l'ensemble (icône + texte) */
}
/* Styles généraux pour la section de présentation */
.presentation-title {
  margin-bottom: 50px;
  margin-top: 50px;
}

.text-column {
  max-width: none;
  margin: 0 auto;
}

.scroll-section {
  background-color: white;
  margin: 0;
  box-sizing: border-box;
}

.scroll-text {
  width: 80%;
  margin: 0 auto;
  max-width: calc(100% - 40px);
  line-height: 1.6;
}

/* Adaptations pour tablettes */
@media (max-width: 768px) {
  .text-column {
    width: 100%;
  }
  .scroll-text {
    font-size: 1.1rem;
    text-align: justify;
    hyphens: auto;
    text-justify: inter-word;
    word-spacing: 0.05em;
    letter-spacing: 0.5px;
  }
}

/* Adaptations pour écrans moyens */
@media (max-width: 1200px) {
  .scroll-text {
    font-size: 0.85rem;
  }
}

/* Accordéon */
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}

/* Icône de l'accordéon */
.toggle-icon {
  transition: transform 0.4s ease, opacity 0.4s ease;
  cursor: pointer;
}

/* Animation lors de l'ouverture : on peut, par exemple, effectuer une légère rotation */
.toggle-icon.open {
  transform: rotate(180deg);
  opacity: 0.8;
}
